import React, { Component } from "react";
import { ReactNavbar } from "react-responsive-animate-navbar";
import "./App.css";

import firebase from "firebase/app";
import "firebase/analytics";

firebase.initializeApp({
  apiKey: "AIzaSyAED0EYHhku-K6GXLVIyFNbg-ZK-NXi5w8",
  authDomain: "lineware-web.firebaseapp.com",
  databaseURL: "https://lineware-web.firebaseio.com",
  projectId: "lineware-web",
  storageBucket: "lineware-web.appspot.com",
  messagingSenderId: "376631133097",
  appId: "1:376631133097:web:4c52a4468faa0c9c4ffab8",
  measurementId: "G-6YLTT43BCJ",
});

firebase.analytics();

class App extends Component {
  
  render() {
    return (
      <html>
        <div className="topbar" id="topbar">
          <ReactNavbar
            color="#141414"
            font-size="28px"
            background_color="#141414"
            logo={require("./res/logo.png")}
            menu={[
              { name: "HOME", to: "/" },
              { name: "PRODUCTS", to: "/products" },
              { name: "CONTACT", to: "/contact" },
            ]}
            social={[]}
          />
        </div>

        <div class="middle-layout">
          <p class="m-header">What do we do?</p>
          <p class="m-desc">We are developing things for a better life.</p>

          <p class="m-header">What do we develop?</p>
          <p class="m-desc">
            We are developing mobile apps, desktop software and tools. Also we
            are planning to develop embedded systems, robotics and artificial
            intelligence technology.
          </p>

          <p class="m-header">What did we do?</p>
          <p class="m-desc">Here are some of our products:</p>
        </div>

        <div id="products-layout" class="products-layout">
          <img src={require("./res/atom.png")} className="app-icon" />
          <p class="m-header2">CoreBooster</p>
          <p class="m-desc2">Taste the true power of your Android device!</p>
          <p class="m-desc2">Installed by over 300K+ user</p>

          <a
            href="https://play.google.com/store/apps/details?id=com.emre.androbooster"
            class="material-button-blue"
          >
            Download
          </a>

          <img src={require("./res/corebooster.png")} className="cb1" />
          <img src={require("./res/corebooster2.png")} className="cb1" />
          <img src={require("./res/corebooster3.png")} className="cb1" />
        </div>

        <div id="products-layout" class="products-layout3">
        
          <img src={require("./res/ic_oc.png")} className="app-icon" />
          
          <p class="m-header2">OptimalConnect</p>

          <p class="m-desc3">DNS Changer & Connection Optimizer & Internet Access Blocker/Firewall</p>

          <a
            href="https://play.google.com/store/apps/details?id=com.lineware.opticonnect"
            class="material-button-dark"
          >
            Download
          </a>

          <img src={require("./res/oc-ss1.png")} className="cb1" />
          <img src={require("./res/oc-ss2.png")} className="cb1" />
          <img src={require("./res/oc-ss3.png")} className="cb1" />
        </div>

        <div id="products-layout" class="products-layout2">
         
          <img
            src={require("./res/ic_netfelis_white.png")}
            className="nf-app-icon"
          />
           <p class="m-header2">Netfelis</p>

          <p class="m-desc3">A synchron cyber security otomation software!</p>
          <p class="m-desc3">This product is still under development</p>

          <img src={require("./res/nf1.png")} className="cb1" />
          <img src={require("./res/nf2.png")} className="cb1" />
          <img src={require("./res/nf3.png")} className="cb1" />
        </div>

        <div id="contacts-layout" class="contact-layout">
          <p class="m-header">Contact Info</p>
          <p class="m-desc">For issues, recommendations, and other things:</p>
          <p class="m-desc4">emre@linewaretech.com</p>
          <p class="m-desc4">iletisim@linewaretech.com</p>

          <p class="m-header">Social Media</p>

          <a href="https://twitter.com/LinewareTech">
            <img src={require("./res/twitter.png")} className="twitter-icon" />
          </a>

          <a href="https://www.facebook.com/lineware">
            <img src={require("./res/facebook.png")} className="fb-icon" />
          </a>

          <a href="https://www.instagram.com/linewaretech">
            <img src={require("./res/instagram.png")} className="fb-icon" />
          </a>
        </div>

        <body></body>
      </html>
    );
  }
}

export default App;
