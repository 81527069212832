import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

document.getElementById("topbar").addEventListener("click", function () {
  //  alert('clicked outer div...');
  setTimeout(function () {
    let currentLocation = window.location.href;
    
    if (currentLocation.includes("/products")) {
      document.getElementById("products-layout").scrollIntoView({ behavior: "smooth" });
    } else if (currentLocation.includes("/contact")) {
      document.getElementById("contacts-layout").scrollIntoView({ behavior: "smooth" });
    } else {
      document.getElementById("topbar").scrollIntoView({ behavior: "smooth" });
    }
  }, 50);
});

serviceWorker.unregister();
